@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap");
.bg-image-vertical {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 100%;
}
.login_page_reset_password {
  display: flex;
  justify-content: space-between !important;
  width: 100%;
}
.login_div {
  font-weight: 600 !important;
  font-size: 26px;
  line-height: 37px;
  font-family: jost;
}
.email_address_login {
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  font-family: jost;
}
.reset_login_page {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: jost;
}
.remember_login_page {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: jost;
}
.login_btn_btn {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  font-family: jost;
}
.have_account {
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  font-family: "jost";
}
.reset_button {
  color: rgba(226, 19, 34, 1) !important;
  text-decoration: none;
}
.reset_button:hover {
  color: rgba(226, 19, 34, 1) !important;
}
.input_login_page {
  background-color: rgba(247, 247, 248, 1);
  border: none;
}
/* // signup page csss */
.frisbee_logo_sign_up_main_section {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.sign_up_form_main_class {
  margin-top: 1.5rem;
}
.sign_up_form_main_class h4 {
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
}
.vendor_Add_edit_data input:focus {
  box-shadow: none;
  border: 1px solid black;
}
.vendor_first_name {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.sign_up_vendor_first_name {
  margin-top: 1.3rem;
}
.vendor_Add_edit_data input::placeholder {
  color: #bebebe;
}
.vendor_dashbaord_gender_main_section_signup {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  max-width: 100%;
  justify-content: space-evenly;
  padding: 5px;
  background-color: #fff;
}
.vendor_dashbaord_gender_main_section_signup [type="radio"] {
  border-radius: 50%;
  border-color: #000;
}
.frisbee_dashboard_signup_col_section {
  background-color: #fafafb;
}
.sign_up_file_upload {
  border: 2px solid #dee2e6;
  border-radius: var(--bs-border-radius);
  background: #fff;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 2px;
  padding-inline: 14px;
}
.sign_up_file_upload button {
  padding: 2px 29px;
  background: #2697ff;
  color: #fff;
  border-radius: 25px;
  height: unset;
}
.sign_up_file_upload button:hover {
  padding: 2px 29px;
  background: #2697ff;
  color: #fff !important;
  border-radius: 25px;
  height: unset;
}
.sign_up_file_upload div {
  color: #bebebe;
}
.sign_up_file_upload .ant-upload-wrapper .ant-upload.ant-upload-select {
  height: unset !important;
  width: 100% !important;
  padding: 0 !important;
  margin-top: 0 !important;
  border-radius: 25px;
}
.frisbee_dashbaord_sign_up_select .ant-select-selector {
  border-color: #dee2e6 !important;
  width: 100%;
  padding: 0.375rem rem 0.75rem;
  font-weight: 400;
  line-height: 3.5;
  padding: 5px;
}
.vendor_dashbaord_gender_main_section_signup label {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.sign_up_vendor_square_box {
  width: 82px;
  height: 35px;
  background: #fff;
  border-radius: 6px;
  border: 2px solid #dee2e6;
}
.sign_up_vendor_square_box span {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  color: #c2c2c2;
}
.sign_up_vendor_square_box_main_section {
  display: flex;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.sign_up_vendor_square_box_main_section h6 {
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
}
.sign_up_vendor_square_all_timer_section {
  margin-top: 10px;
}
.sign_up_button_main_section_submit {
  margin-top: 2.3rem;
  margin-bottom: 44px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.sign_up_button_main_section_submit button {
  background: #e1251b;
  width: 25%;
  content: #fff;
  color: #fff;
  font-weight: 400;
  padding: 4px;
  border: none;
  border-radius: 5px;
}
.sign_up_class_for_img img {
  object-fit: cover;
  width: 100%;
  height: 100vh;
}

.scrollable_content_signup_left_side {
  overflow-y: auto;
  max-height: 100vh;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollable_content_signup_left_side::-webkit-scrollbar {
  display: none;
}
