
.App {
    width: 100%;
    overflow: auto;
  }
*{
    margin:0;
    padding:0;
    text-decoration: none;
}
.container{
    display:flex;
}
main{
    width: 100%;
    padding: 20px;
    background-color:#FAFAFB;
    overflow-x: hidden !important;
}

.cancel{
    color: #D11A2A !important;
    font-weight: 500;
}
.pending{
    color: #F29339 !important;
    font-weight: 500;
}
.complete{
    color: #2CC84A !important;
    font-weight: 500;
}
.sidebar{
    background-color:white;
    color:black;
    width: 200px;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    gap: 18px;
}
.card{
    border: none !important;
    border-radius: 10px !important;

}
.card-header{
    background-color: #fff !important;
}
.top_section{
    display: flex;
    align-items:center;
    padding:30px 15px;
}
.logo{
    font-size: 30px;
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
    cursor: pointer;
}
.link{
    display: flex;
    color: black;
    padding: 13px 21px;
    gap: 15px;
    transition: all 0.5s;
    align-items: baseline;
    line-height: 16px;
}
.link:hover{
    background:#fff ;
    color: #263238;
    transition: all 0.5s;
    
}
.icon{
    font-size: 20px;
}
.active{
  background: #263238 !important;
    color: #fff !important;
}
.link_text{
    font-size: 17px;
    line-height: 23.17px;
    font-family: 'Jost', sans-serif;
}
.img-center{
    text-align: center; 
    vertical-align: middle;
  }
@media only screen and (max-width: 768px) {
    main{
        width: 87%;
    }
}
@media only screen and (max-width: 425px) {
    main{
        width: 86%;
    }
}
