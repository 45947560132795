.promotions_table_heading {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
}
.promotions_heading {
  font-family: jost;
  font-weight: 600;
  font-size: 22px;
  line-height: 31px;
}
.promotions_heading_filter {
  font-family: jost;
  font-weight: 500;
  font-size: 18px;
  line-height: 31px;
}
.promotion_filter_color {
  color: black;
  cursor: pointer;
}
.promotions_filters_div {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.Promotions_faPlus_Squares {
  margin-bottom: 3px;
  margin-right: 13px;
}
.promotion_filter {
  margin-bottom: 4px;
}
/* //Card class */
.promotions_card_class {
  margin-top: 20px;
}
.promotion_card_child_class {
  border-radius: 15px;
}
.promotion_num {
  text-decoration: none;
  font-weight: 700;
  font-size: 26px;
  line-height: 37px;
}
.promotion_card_body {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.promotions_represents {
  margin-bottom: 4rem;
  font-family: jost;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(180, 180, 180, 1);
}
.promotion_Active_promocodes {
  font-family: jost;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
/* // Create PopUp css */
.promotions_create_popup_amount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.admin_promotions_create_popup_amount {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.admin_promotion_create_amount span {
  font-weight: 600;
  font-size: 16px;
  line-height: 23.12px;
}
.promotion_create_amount {
  width: 50%;
}
.promotion_plceholder::placeholder {
  font-weight: 600;
  font-size: 14px;
}
.categories_select_limit {
  padding: 10px;
}
.categories_select_limit .ant-input-number-handler-wrap {
  opacity: 1 !important;
}
.ant-input-number:hover,
.ant-input-number-focused {
  box-shadow: none !important;
}

.promotions_date_picker_antd {
  padding: 15px;
}

.promotions_date_picker_antd:where(
    .css-dev-only-do-not-override-dkbvqv
  ).ant-picker:hover {
  border-color: #e4e1e1 !important;
}
.promotions_date_picker_antd:where(
    .css-dev-only-do-not-override-dkbvqv
  )[class^="ant-picker"],
:where(.css-dev-only-do-not-override-dkbvqv)[class*=" ant-picker"] {
  z-index: 999999 !important;
}
.promotions_date_picker_antd .ant-picker-input input::placeholder {
  color: rgba(85, 85, 85, 1) !important ;
}
:where(.css-dev-only-do-not-override-dkbvqv).ant-input-number:hover {
  border-color: #e4e1e1 !important;
}
:where(.css-dev-only-do-not-override-dkbvqv).ant-input-number:focus {
  border: 1px solid black !important;
  box-shadow: none;
}
.promotions_date_picker_antd:where(
    .css-dev-only-do-not-override-dkbvqv
  ).ant-picker
  .ant-picker-suffix {
  color: black !important;
}
.promotions_date_picker_antd:where(
    .css-dev-only-do-not-override-dkbvqv
  ).ant-picker
  .ant-picker-suffix
  > * {
  font-size: 23px !important;
}
.notification_image_mai_section{
  max-width: 65px;
}
@media only screen and (max-width: 768px) {
  .promotion_Active_promocodes {
    font-size: 18px;
  }
  .promotions_heading {
    font-size: 18px;
  }
  .promotion_num {
    font-size: 18px;
  }
  .promotions_table_heading {
    flex-wrap: wrap;
  }
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  .promotion_card_child_class {
    margin-bottom: 15px;
  }
  .promotions_card_class {
    margin-top: 20px;
  }
  .promotions_filters_div {
    display: flex;
    flex-direction: row;
    gap: 13px;
}
}

@media only screen and (max-width: 425px) {
  .Promotions_faPlus_Squares {
    font-size: 10px;
  }
  .Promotions_faPlus_Squares {
    margin-bottom: 0px;
  }
}
