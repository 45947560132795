.filters_inventory_Rating_review {
  /* margin-top: 9px; */
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
}
.filter_btn_invontry_rating_review {
  font-family: jost;
  color: #333;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
.payment_select_tag_rating_review .ant-select-selector {
  background-color: rgba(252, 233, 232, 1) !important;
  border-color: red !important;
  border: none !important;
}
.fa_filter_rating_review {
  margin-right: 20px;
}
.all_tabs_value_rating_review {
  margin-left: 20px;
}
.orders_now_IoArrow {
  cursor: pointer;
  background-color: white;
  border-radius: 6px;
  margin-bottom: 6px;
  width: 36%;
  height: 33px;
}

.myDrawer {
  /* width: 300px; */
  padding: 20px;
  /* height: 500px; */
  border-radius: 30px;
}
.drawer_for_width {
  width: 300px;
}

.myMainDrawerClass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
}
.myMainDrawerClass .css-h93ljk-MuiTypography-root {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.334;
  letter-spacing: 0em;
  margin-bottom: 0em;
}
.myFilterClass {
  font-size: 18px !important;
  margin-right: 16px !important;
  font-family: jost !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  color: #000 !important;
}
.reuest_list_form_section {
  margin-top: 25px;
}
.reuest_list_form_section_div {
  display: flex;
  gap: 15px;
}
.reuest_list_form_section_button {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.reuest_list_form_section_button button {
  background: black;
  color: #fff;
  /* padding: 8px 102px 8px 112px; */
  background: black;
  width: 100%;
  border-radius: 6px;
  color: #fff;
  padding: 5px 4px 5px 4px;
}
.reuest_filter_drawer_main_section .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  border-top-left-radius: 16px !important;
}
.reuest_list_form_section_div input {
  width: 20px;
  height: 20px;
  color: #000;
}
.reuest_list_form_section_div label {
  font-family: "Jost" !important;
  font-size: 15px;
  font-weight: 400;
}
/* //dropdown css */
.vendor_list_dropdown_th {
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}
.vendor_list_dropdown_th:active {
  color: #000 !important;
}
.vendor_list_dropdown_th:hover {
  color: #000 !important;
}
.vendor_list_dropdown_th:focus-visible {
  outline: none !important;
}
.dropdown_wrapper_div {
  display: flex;
  align-items: center;
}
.filter_icon_imgg {
  margin-left: 10px;
  cursor: pointer;
}
.filter_icon_imgg_button{
  background: none;
}
.order_list_mai_section_div{
  border-bottom: none  !important;
  margin-left: 7px;
}
.store_input_field_field:focus {
  box-shadow: none;
  border: 1px solid black;
}
@media only screen and (max-width: 768px) {
  .all_tabs_value_rating_review {
    margin-left: 4px;
    display: flex;
    width: 100%;
    overflow: scroll !important;
  }
  .filters_inventory_Rating_review {
    margin-left: 20px;
    margin-bottom: 13px;
  }
}
@media only screen and (max-width: 425px) {
  .all_tabs_value_rating_review button {
    padding: 6px;
    overflow: scroll !important;
  }
  .categories_main_div {
    flex-direction: column;
  }
  .promotions_filters_div {
    gap: 10px;
    margin-top: 10px;
  }
  .filters_inventory_Rating_review {
    gap: 14px;
  }
  .filter_btn_invontry_rating_review {
    font-size: 15px;
  }
  .fa_filter_rating_review {
    margin-right: 0px;
    margin-top: 8px;
  }
}
