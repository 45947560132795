.order_now_himalays {
  text-decoration: none !important;
  font-size: 15px;
  font-family: jost;
  line-height: 21px;
  font-weight: 400;
}
.orders_now_image {
  width: 100px;
}
.order_now_qty {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  margin: 10px 0px;
}
.orders_now_table tr {
  border-bottom: 2px solid #eee;
}
.orders_now_billing_details_heading {
  font-family: "Jost";
  font-size: 16px;
  font-weight: 600;
  line-height: 23.12px;
  margin-left: 6px;
}
.booking_details_billing_details_heading {
  font-family: "Jost";
  font-size: 18px;
  font-weight: 600;
  line-height: 26.1px;
}
.orders_now_subtotal {
  font-family: "Jost" !important;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 20.23px;
  color: rgba(0, 0, 0, 1) !important;
}
.order_now_rupees {
  font-family: "Jost" !important;
  font-size: 15px;
  font-weight: 400 !important;
  line-height: 21.48px;
}
.order_now_address {
  display: flex;
  flex-direction: column;
  padding: 7px;
  margin-top: 10px;
  margin-bottom: 0px;
}
.order_now_address address {
  margin-bottom: 0rem !important;
}
.order_now_address span {
  font-family: "Jost";
  font-weight: 500;
  font-size: 14px;
  line-height: 20.23px;
  color: rgba(85, 85, 85, 1);
  margin-bottom: 0rem !important;
}
.booking_deatils_order_now_address {
  display: flex;
  flex-direction: column;
  padding: 7px;
  margin-top: 10px;
  margin-bottom: 0px;
}
.booking_deatils_order_now_address span {
  font-family: "Jost";
  font-weight: 500;
  font-size: 14px;
  line-height: 20.23px;
  color: rgba(85, 85, 85, 1);
  margin-bottom: 0rem !important;
}
.order_now_card_tilte {
  margin-bottom: 30px;
  margin-top: 10px;
  margin-top: 2%;
}
.order_now_address_date_and_time {
  margin-left: 8px;
  margin-top: 8px;
  text-align: justify;
}

.order_now_date_and_time_section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
}
.order_now_heading_button {
  margin-top: 15px;
  display: flex;
  gap: 10px;
}
.order_now_heading_button1 {
  background-color: rgba(236, 245, 238, 1);
  color: rgba(44, 200, 74, 1);
  border: none;
  padding: 8px 22px 8px 22px;
  border-radius: 7px;
  margin-right: 15px;
  font-family: "Jost";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.order_now_heading_button1 svg {
  margin-bottom: 2px;
}
.order_now_heading_button_datepicker {
  margin-right: 15px;
  border: 1px solid black;
}
.order_now_heading_button_datepicker:hover {
  border: 1px solid black;
}
.order_now_heading_button_datepicker .ant-picker-input {
  display: flex;
  flex-direction: row-reverse;
}
.order_now_heading_button_datepicker .ant-picker-input input {
  margin-left: 10px;
}
.order_now_heading_button_datepicker .ant-picker-suffix {
  left: 8px !important;
}
/* .order_now_heading_button_datepicker .ant-picker-footer{

} */
.order_now_heading_button2 {
  background-color: rgba(255, 242, 243, 1);
  color: rgba(209, 26, 42, 1);
  border: none;
  padding: 8px 22px 8px 22px;
  border-radius: 7px;
  font-family: "Jost";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.order_now_heading_button2 svg {
  margin-bottom: 2px;
}
.order_now_billing_details_border {
  border-bottom: 2px solid #eee;
}
.booking_details_top_icon svg {
  font-size: 13px;
}
.booking_deatils_heading {
  font-size: 16px;
  font-weight: 400;
  margin-left: 3px;
}
.order_now_address_date_and_time span {
  font-family: "Jost" !important;
  font-weight: 500;
  font-size: 13px;
  line-height: 18.79px;
  color: rgba(153, 153, 153, 1);
}
.booking_deatils_table_for_tr {
  margin-bottom: 0rem;
}
.booking_deatils_table_for_tr tr,
td {
  border-bottom: none !important;
}
.booking_details_convenience {
  font-size: 14px;
  font-weight: 500;
  line-height: 20.23px;
  color: rgba(153, 153, 153, 1);
}
.booking_details_payable_amount {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 23.12px;
}
.booking_details_status_button {
  width: 100%;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}
.booking_now_heading_button1 {
  background-color: rgba(236, 245, 238, 1);
  color: rgba(44, 200, 74, 1);
  border: none;
  padding: 8px 22px 8px 22px;
  border-radius: 7px;
  margin-right: 15px;
  font-family: "Jost";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  width: 50%;
}
.booking_now_heading_button2 {
  background-color: rgba(255, 242, 243, 1);
  color: rgba(209, 26, 42, 1);
  border: none;
  padding: 8px 22px 8px 22px;
  border-radius: 7px;
  font-family: "Jost";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  width: 50%;
}
.booking_details_assign_vendor {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.booking_details_dog_star {
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  color: rgba(85, 85, 85, 1);
}
.booking_now_IoArrow {
  cursor: pointer;
  background-color: white;
  border-radius: 6px;
  margin-bottom: 6px;
  width: 6%;
  height: 33px;
}
.order_now_vendor_re_schedule {
  border: 1px solid;
  padding: 5px 23px;
  border-radius: 8px;
  /* margin: 19px; */
  cursor: pointer;
}
/* // Reshedule css */
.re_schedule_main_section {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.re_schedule_main_section_child_section {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  margin-left: 1px;
}
.time_link_vendor {
  color: white;
  text-decoration: none;
  font-size: 11px;
}
.time_link_vendor_tag {
  color: #000;
  text-decoration: none;
  font-size: 11px;
}
.re_schedule_main_section_child_section .ant-picker {
  width: 80%;
  border-color: gray;
}
.re_schedule_main_section_child_section .ant-picker:hover {
  border-color: gray;
}
.drawer_for_width_re_schedule {
  width: 400px;
}
.vendor_reschedule_card_section {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 15px;
  /* padding-inline: 32px; */
}
.re_schedule_child_card {
  /* background: gray; */
  border: 1px solid black !important;
  width: 95%;
}
.re_schedule_child_card .card-body {
  padding-right: 0;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 1px;
  height: 18px;
}
.react_calander_in_sign_up {
  border-radius: 10px;
}
.booking_id_class {
  margin-top: 25px;
}
.re_schedule_child_card span {
  text-align: center;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 5px;
}
.re_schedule_card_all_section .col-sm-4 {
  padding-right: 0;
  padding-left: 0;
}
.re_schedule_card_all_section {
  display: flex;
  /* gap: 8px; */
  flex-direction: row;
  /* flex-wrap: unset; */
}
.re_schedule_child_card_first_card {
  background: red;
  color: #fff;
  border: none !important;
}
.re_schedule_child_card_second_child {
  background: #ebebeb;
  /* color: #fff; */
}

@media only screen and (max-width: 500px) {
  .order_now_heading_button1 {
    margin-bottom: 10px;
  }
}


.reqdivdecline{
  background-color: rgb(255 242 243 / 44%);
  border: 1px solid #ff72726e !important;
}

.reqdivpending {
  background-color: rgb(236 199 102 / 9%);
    border: 1px solid #e7c1166e !important;
}

.reqdivaccepted {
  background-color: rgb(183 255 182 / 5%);
  border: 1px solid #32c7586e !important;
}
