@import url("https://fonts.googleapis.com/css2?family=Birthstone&family=DM+Sans:opsz@9..40&family=Jost:wght@500&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Oswald:wght@600&family=Pacifico&family=Poppins:wght@500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");

* {
  font-family: "Jost", sans-serif;
}
/* .main_dashbord {
} */
.star_reviews span {
  margin-right: 10px;
}

.main_heading {
  font-family: "Jost", sans-serif;
  font-size: 24px;
  color: #263238;
  font-weight: 700;
  line-height: 37.53px;
}
.btn_icon {
  text-align: end;
  border: navajowhite;
  padding: 4px 11px 7px;
  border-radius: 50%;
  background-color: #fce9e8;
  color: #e1251b;
  font-size: 21px;
}
.search {
  position: relative;
}
.search_box #search_icon {
  position: absolute;
  left: 14px;
  top: 13px;
  font-size: 20px;
}
.search_box {
  position: relative;
}
.search::placeholder {
  color: #000000;
  font-size: 16px;
  font-family: "Jost", sans-serif;
  line-height: 20px;
}
.search_input {
  padding: 9px 10px 11px 43px;
  border: 1px solid #f3f3f3;
  width: 100%;
  border-radius: 7px;
}
.transction_main_Card h1,
.review h1 {
  color: #263238;
  font-size: 20px;
  line-height: 30px;
  margin: 0px;
}
.transction_main_Card {
  display: flex;
  justify-content: space-between;
}
.transction_main_Card select {
  border: none;
  font-size: 13px;
  color: #263238;
  font-weight: 400;
  width: 74px;
}
.transaction_Card,
.review {
  background-color: #ffffff;
  padding: 11px 15px;
  margin: 17px 0px;
  border-radius: 10px;
}
.star_reviews ul li {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
}
.star {
  color: #f29339;
  font-size: 23px;
  margin-right: 5px;
}
.select_option {
  color: #b4b4b4 !important;
}
.half-star {
  color: #f29339;
  opacity: 20%;
  font-size: 23px;
}
.sticky {
  position: fixed;
  z-index: 99999;
  background-color: #ffffff;
  width: 100%;
  padding: 10px 22px;
  top: 0px;
  left: 0px;
  box-shadow: 0 4px 12px 0 #0000000a;
  color: var(--color-default);
  /* left: 289px; */
}
.star_reviews {
  margin-right: 23px;
  font-size: 32px;
  font-weight: 500;
  color: #263238;
  display: flex;
  align-items: baseline;
  justify-content: end;
}
.star_reviews ul {
  padding: 0px;
  margin: 0px;
  display: flex;
}
.tran_details img {
  width: 41px;
  height: 41px;
}
.transaction_Card ul {
  padding: 0px 5px 0px;
  width: 100%;
  margin: 0px;
}
.tran_details {
  display: flex;
}
.transaction_Card ul li:nth-last-child(1) {
  border-bottom: 0px solid #cccccca6;
}
.transaction_form {
  display: flex;
}
.margin-right {
  margin-right: 10px;
}
.transaction_form .transaction_Card {
  width: 100%;
  height: 168px;
  position: relative;
}
.transaction_Card ul li {
  display: flex;
  list-style: none;
  padding: 14px 0px;
  justify-content: space-between;
  border-bottom: 1px solid #cccccca6;
  margin: 10px 0px;
}
.trans_name {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 20.23px;
}
.trans_id {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20.23px;
}
.tran_infor {
  margin-left: 20px;
}
.tran_infor p {
  margin: 0px;
}
.price {
  color: #000000;
  font-weight: 500;
  font-size: 15px;
  line-height: 20.23px;
}
.sales_text {
  font-size: 23px;
  font-weight: 500;
  line-height: 48px;
  margin: 0px;
  position: absolute;
  bottom: 0px;
}
.presentation {
  font-size: 16px;
  margin: 15px 0px 12px;
  color: #263238;
  opacity: 34%;
  font-weight: 400;
}
.unit {
  color: #7b7b7b;
  font-size: 15px;
}
/* .header_top_nav{

} */
.btn_icon {
  text-align: end;
  border: navajowhite;
  padding: 4px 11px 7px;
  border-radius: 50%;
  background-color: #fce9e8;
  color: #e1251b;
  font-size: 21px;
}
.btn_icon:hover {
  color: #e1251b;
  background-color: #fce9e8;
  border-color: white;
}
.btn_icon:active {
  color: #e1251b !important;
  background-color: #fce9e8 !important;
  border-color: white !important;
}
.btn_icon:focus {
  color: #e1251b !important;
  background-color: #fce9e8 !important;
  border-color: white !important;
}
.btn_icon:focus-visible {
  color: #e1251b !important;
  background-color: #fce9e8 !important;
  border-color: white !important;
  border: transparent;
}
.top_nav_oo .btn-primary {
  color: #e1251b !important;
  background-color: #fce9e8 !important;
  border-color: white !important;
  border: transparent;
}
.top_nav_oo button::after {
  display: none;
}
.admin_dashboard_dropdwon:active {
  color: black !important;
  text-decoration: none;
  background-color: white !important;
}
.main_dashboard_tooltip {
  display: flex;
  flex-direction: row;
}
.select_filter_class .ant-select-selection-item {
  color: rgb(82, 82, 106);
}
.vendor_dashbaord_product_img {
  max-width: 100px;
  height: auto;
}
.vendor_dashbaord_product_info {
  display: flex;
  align-items: center;
}

.vendor_dashbaord_product_img {
  margin-right: 10px;
}
/* //Dashboard chart css */
.inventory_tabs_main_div_card {
  padding-left: 0;
}
.notification-icon {
  position: relative;
  display: inline-block;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 14px;
}



/* Apply alternating background colors for each td */
.table tbody tr:nth-child(even) td {
  background-color: #ffffff;
  /* White for even rows */
}

.table tbody tr:nth-child(odd) td {
  background-color: #fbf4f3;
  /* Light gray for odd rows */
}
.table>tbody>tr:hover>* {
  background-color: #c1c1c1 !important;
}

/* Basic table styling */
.table td {
  /* padding: 10px; */
  border: 1px solid #ccc;
  /* text-align: center;    */
  vertical-align: middle; 
}
.table td p{
  margin-bottom: 0 !important; 
}
@media only screen and (max-width: 768px) {
  .transaction_form {
    width: 100%;
    flex-direction: column;
  }
  .search_input {
    width: 100%;
  }
  .transaction_Card,
  .review {
    width: 100%;
  }
  .sticky {
    position: absolute;
  }
  .my_dropdown_main_dashboard {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
    gap: 10px;
  }
  .main_dashboard_tooltip {
    margin-top: 8px;
    width: fit-content;
    gap: 12px;
  }
}
@media only screen and (max-width: 425px) {
  .main_heading {
    font-size: 20px;
  }
  .search_input {
    padding: 9px 10px 7px 32px;
  }
  .btn_icon {
    font-size: 16px;
    margin-top: 5px;
  }
  .sales_text {
    font-size: 16px;
  }
  .transction_main_Card h1,
  .review h1 {
    font-size: 18px;
  }
}
