@import url("https://fonts.googleapis.com/css2?family=Birthstone&family=DM+Sans:opsz@9..40&family=Jost:wght@500&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Oswald:wght@600&family=Pacifico&family=Poppins:wght@500&display=swap");

* {
  font-family: "Jost", sans-serif;
}
.user_profile_pic {
    /* width: 91%; */
    /* padding-top: 4px; */
    max-width: 46px;
    max-height: 46px;
    border-radius: 50%;
}
.user_profile_main_class {
  display: flex;
  flex-direction: row;
  padding: 6px;
  align-items: center;
}
.user_profile_section {
  margin-top: 2%;
}
.profile_user_name {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}
.profile_user_admin {
  color: rgba(119, 119, 119, 1);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.profile_store_details {
  margin-top: 2%;
}
.profile_store_location {
  font-weight: 500;
  font-size: 14px;
  line-height: 37px;
}
.profile_store_location1 {
  color: rgba(119, 119, 119, 1);
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
}
.profile_details_location {
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
}
.profile_details_section {
  line-height: 50px;
}
/* .Bank_details_profile_store_table tr{

} */
.profile_store_details_for_edit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
/* //store details form section */
/* .store_details_form_main_section{
   display: flex; 
}
.store_details_form_main_section_child_section{

} */
.store_details_form_owner_name {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.store_input_field {
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 21px;
  border-radius: 11px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(119, 119, 119, 1);
}
.store_input_field:focus {
  box-shadow: none;
  border: 1px solid black;
}
.profile_store_details_heading {
  font-weight: 500;
  font-size: 22px;
  line-height: 31px;
}
.profile_label_store_name {
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  margin-bottom: 10px;
  color: rgb(15 10 10);
}

.store_details_collapse.ant-collapse {
  overflow: hidden !important;
}
.store_details_collapse.ant-collapse:hover {
  border: 1px solid #000;
}

.store_details_all_form_section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.profile_store_timing_main_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4%;
  width: 100%;
}
.store_details_monday {
  width: 50%;
  margin-bottom: 30px;
}
.store_details_monday_div {
  width: 100%;
}

.switch_panel_header {
  display: flex;
  align-items: center;
}
.categories_switch_ {
  margin-right: 8px;
}
.store_time_picker {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}
.store_deatils_time_picker_contnet_section {
  width: 50%;
}
.store_time_picker_main_section.ant-picker-large {
  width: 100%;
}
.store_time_picker_main_section.ant-picker:hover {
  border-color: rgba(119, 119, 119, 1) !important;
}
.store_time_picker_main_section.ant-picker-focused.ant-picker {
  border-color: rgba(119, 119, 119, 1) !important;
  box-shadow: rgba(119, 119, 119, 1) !important;
}
:where(.css-dev-only-do-not-override-dkbvqv).ant-picker-focused.ant-picker {
  box-shadow: 0 0 0 0px rgba(119, 119, 119, 1) !important;
}
.store_time_picker_main_section:where(
    .css-dev-only-do-not-override-dkbvqv
  ).ant-btn-primary {
  background-color: black !important;
}
:where(.css-dev-only-do-not-override-dkbvqv).ant-btn-primary {
  background-color: black !important;
}
:where(.css-dev-only-do-not-override-dkbvqv) a {
  text-decoration: none !important;
  color: black !important;
}
.store_details_to {
  width: 10%;
  text-align: center;
  margin-top: 9px;
}
.store_details_all_collapse {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.ant-collapse-content.ant-collapse-content-active {
  border-top: none;
}
.ant-collapse-header {
  background: #fff;
}
:where(.css-dev-only-do-not-override-dkbvqv).ant-collapse {
  overflow: hidden;
}
/* :where(.css-dev-only-do-not-override-dkbvqv).ant-collapse:hover{
    border: 2px solid black;
  } */
.store_profile_weekly_name {
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
}
.store_details_form_submit_button {
  text-align: end;
  margin-top: 4%;
}
.store_details_form_btn {
  padding: 12px;
  width: 32%;
}
.bottom_profile_account_noo{
  width: 50% !important;
}
@media only screen and (max-width: 768px) {
  .store_details_form_owner_name {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .profile_store_timing_main_div {
    flex-direction: column;
  }
  .store_details_monday {
    width: 100%;
  }
  .store_time_picker {
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
  .store_time_picker_main_section.ant-picker-large {
    width: 100%;
  }
  .store_details_to {
    display: none;
  }
  .store_deatils_time_picker_contnet_section {
    width: 100%;
  }
}
